import { useMatch } from 'react-router';
import { paths } from '@/routes-utils/paths.ts';
import { header, headerContent, headerWithBg } from './page-header.css';
import clsx from 'clsx';
import { useIsTablet } from '@synoptic/ui-kit/responsive/hooks.ts';
import { BackButton } from '@/components/page-header/back-button.tsx';
import { DrawerAvatarTrigger } from '@/components/page-header/drawer-avatar-trigger.tsx';

type PageHeaderProps = {
  /** Navigate to this location on Back button click in case of empty history stack */
  fallbackReturnTo?: string;
  noBackground?: boolean;
};

export const PageHeader: React.FC<React.PropsWithChildren<PageHeaderProps>> = ({
  children,
  fallbackReturnTo,
  noBackground = false,
}) => {
  const isHome = useMatch(paths.default);
  const isTablet = useIsTablet();

  return (
    <div className={clsx(header, !noBackground && headerWithBg)}>
      <div className={headerContent}>
        {isHome ? null : <BackButton fallbackReturnTo={fallbackReturnTo} />}
        {children}
      </div>
      {!isTablet ? <DrawerAvatarTrigger /> : null}
    </div>
  );
};
